import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, from, Observable, of, reduce } from 'rxjs';
import { ResponseArrayInterface } from '../../../model/interface/generic-api-response.model';
import { IActivityHistory } from '../../activity-history/activity-history.model';
import {
  IActivityTimelineFetchData,
  IActivityTimelineFetchResponse,
  IActivityTimeLineLineResponse,
  IActivityTimelineRequest,
  IActivityTimelineStationActivityHistory,
} from '../../../../store/reports/activity-timeline/activity-timeline.model';
import { HelperService } from '../../helper.service';
import { ActivityTypes } from '../../../model/enum/activity-types';
import { ActivityTimelineInterface } from '../../../../view/reports/activity-timeline/activity-timeline.model';
import { LineCRUDInterface } from '../../../component/filter/filter.class';
import * as moment from 'moment';
import { mysqlDateFormat, mysqlTimestampFormat } from '../../../helper/date';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { GetManyResponseInterface } from '../../../model/interface/crud-response-interface.model';
import { IStationActivityHistoryCrudResponse } from '../../../../store/station-activity-history/station-activity-history.model';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { EntityTranslatorService } from '../../entity-translator/entity-translator.service';
import { StationActivityHistoryService } from '../../station-activity-history/station-activity-history.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityTimelineService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    private readonly helperService: HelperService,
    private readonly translate: TranslateService,
    private translatorService: EntityTranslatorService,
    private readonly stationActivityHistoryService: StationActivityHistoryService,
  ) {}

  private readonly routes = {
    activityHistory: `${this.api}/activity-histories`,
    lines: `${this.api}/lines`,
    stations: `${this.api}/line-stations`,
  };

  public loadInitialActivityData(objectData: IActivityTimelineRequest): Observable<{
    activityHistories: ResponseArrayInterface<IActivityHistory>;
    stationActivityHistories: GetManyResponseInterface<IStationActivityHistoryCrudResponse>;
  }> {
    const requestParameters: HttpParams = this.getStationActivityHistoryRequestParameters(objectData, 1, 1);

    const observables: {
      activityHistories: Observable<ResponseArrayInterface<IActivityHistory>>;
      stationActivityHistories: Observable<GetManyResponseInterface<IStationActivityHistoryCrudResponse>>;
    } = {
      activityHistories: this.loadActivityHistoriesActivityTimeline(objectData, 1, 1),
      stationActivityHistories: objectData.stationIds.length
        ? this.stationActivityHistoryService.getManyStationActivityHistories(requestParameters)
        : of({
            data: [],
            total: 0,
          } as GetManyResponseInterface<IStationActivityHistoryCrudResponse>),
    };

    return forkJoin(observables).pipe(
      map(
        (resolvedObservables: {
          activityHistories: ResponseArrayInterface<IActivityHistory>;
          stationActivityHistories: GetManyResponseInterface<IStationActivityHistoryCrudResponse>;
        }) => {
          return {
            activityHistories: resolvedObservables.activityHistories,
            stationActivityHistories: resolvedObservables.stationActivityHistories,
          };
        },
      ),
    );
  }

  public fetchActivityTimelineData(
    params: IActivityTimelineRequest,
    activityHistoryTotalCount: number,
    stationActivityHistoryTotalCount: number,
  ): Observable<IActivityTimelineFetchData> {
    const partCount: number = 3000;
    const activityHistoryPaginationCount: number = Math.ceil(activityHistoryTotalCount / partCount);
    const stationActivityHistoryPaginationCount: number = Math.ceil(stationActivityHistoryTotalCount / partCount);

    if (activityHistoryPaginationCount > 1 || stationActivityHistoryPaginationCount > 1) {
      this.helperService.showWaitMessage();
    }

    const requestParameters: HttpParams = this.getStationActivityHistoryRequestParameters(params, 1, 1000, true);
    const mainObservables = {
      allLines: this.loadLinesActivityTimeline(params, true),
      lines: this.getLinesActivityTimelineData(params),
      ongoingStations: params.stationIds.length
        ? this.stationActivityHistoryService.getManyStationActivityHistories(requestParameters)
        : of({
            data: [],
            total: 0,
          } as GetManyResponseInterface<IStationActivityHistoryCrudResponse>),
    };

    const activityHistoriesObservable: Observable<ResponseArrayInterface<IActivityHistory>> =
      this.getActivityHistoriesWithPagination(params, activityHistoryPaginationCount, partCount);

    const stationActivityHistoriesObservable: Observable<
      GetManyResponseInterface<IStationActivityHistoryCrudResponse>
    > = this.getStationActivityHistoriesWithPagination(params, stationActivityHistoryPaginationCount, partCount);

    return forkJoin(mainObservables).pipe(
      mergeMap((mainData) =>
        forkJoin({
          activityHistories: activityHistoriesObservable,
          stationActivityHistories: stationActivityHistoryPaginationCount
            ? stationActivityHistoriesObservable
            : of({
                data: [],
                total: 0,
              } as GetManyResponseInterface<IStationActivityHistoryCrudResponse>),
        }).pipe(
          map(({ activityHistories, stationActivityHistories }) => ({
            allLines: mainData.allLines,
            lines: mainData.lines,
            ongoingStations: mainData.ongoingStations,
            activityHistories,
            stationActivityHistories,
          })),
        ),
      ),
      map((resolvedObservables: IActivityTimelineFetchResponse) => {
        this.translateTimelineData(resolvedObservables);

        return {
          allLines: resolvedObservables.allLines.data,
          lines: resolvedObservables.lines.data,
          ongoingStations: resolvedObservables.ongoingStations.data,
          activityHistories: resolvedObservables.activityHistories.data,
          stationActivityHistories: resolvedObservables.stationActivityHistories.data,
        };
      }),
    );
  }

  private loadActivityHistoriesActivityTimeline(
    objectData: IActivityTimelineRequest,
    page: number,
    limit: number,
  ): Observable<ResponseArrayInterface<IActivityHistory>> {
    const filter = {
      $and: [
        objectData.isBusinessDate
          ? {
              shiftDay: { $lte: objectData.endDate, $gte: objectData.startDate },
            }
          : {
              start: { $lte: objectData.endDate },
              end: { $gt: objectData.startDate },
            },
        objectData.siteId !== -1 && (objectData.siteId as Number[]).length !== 0
          ? {
              siteId: { $in: objectData.siteId },
            }
          : '',
        objectData.lineId !== -1 && (objectData.lineId as Number[]).length !== 0
          ? {
              lineId: { $in: objectData.lineId },
            }
          : '',
      ],
    };

    const queryParams: HttpParams = new HttpParams()
      .append('join', 'activity')
      .append('join', 'task')
      .append('join', 'workOrder||woNumber,completed')
      .append('join', 'workOrder.product')
      .append('join', 'user||userName')
      .append('join', 'workOrder.job||jobName')
      .append('fields', 'siteId,lineId,start,end,activitySequenceId')
      .append('useReplicaForGetIfAvailable', true)
      .append('s', JSON.stringify(filter))
      .append('page', page)
      .append('limit', limit)
      .append('isActivityTimeline', true);

    return this.http.get<ResponseArrayInterface<IActivityHistory>>(`${this.routes.activityHistory}`, {
      params: queryParams,
    });
  }

  private loadLinesActivityTimeline(
    objectData: IActivityTimelineRequest,
    isGetAllLines: boolean = false,
  ): Observable<ResponseArrayInterface<LineCRUDInterface>> {
    const filter = {
      $and: [
        {
          timer: { $lte: objectData.endDate },
        },
        objectData.siteId !== -1 && (objectData.siteId as Number[]).length !== 0
          ? {
              siteId: { $in: objectData.siteId },
            }
          : '',
        objectData.lineId !== -1 && (objectData.lineId as Number[]).length !== 0
          ? {
              id: { $in: objectData.lineId },
            }
          : '',
      ],
    };

    let queryParams: HttpParams = new HttpParams()
      .append('useReplicaForGetIfAvailable', true)
      .append('join', 'currentWorkOrder')
      .append('join', 'currentWorkOrder.job||jobName')
      .append('limit', 1000);

    if (!isGetAllLines) {
      queryParams = queryParams
        .append('join', 'currentActivity')
        .append('join', 'currentTask')
        .append('join', 'currentTask.equipment')
        .append('join', 'currentWorkOrder')
        .append('join', 'currentWorkOrder.product')
        .append('fields', 'title,timer')
        .append('s', JSON.stringify(filter));
    }

    return this.http.get<ResponseArrayInterface<LineCRUDInterface>>(`${this.routes.lines}`, {
      params: queryParams,
    });
  }

  private translateTimelineData(data: IActivityTimelineFetchResponse): void {
    data.allLines.data.forEach((line: LineCRUDInterface) => this.translatorService.translate(line));

    data.lines.data.forEach((activityTimeline: IActivityTimeLineLineResponse) =>
      this.translatorService.translate(activityTimeline),
    );

    data.activityHistories.data.forEach((history: IActivityHistory) => this.translatorService.translate(history));
    data.ongoingStations.data.forEach((ongoingStation: IStationActivityHistoryCrudResponse) =>
      this.translatorService.translate(ongoingStation),
    );
    data.stationActivityHistories.data.forEach((stationActivityHistory: IStationActivityHistoryCrudResponse) =>
      this.translatorService.translate(stationActivityHistory),
    );
  }

  private getActivityHistoriesWithPagination(
    params: IActivityTimelineRequest,
    paginationCount: number,
    partCount: number,
  ): Observable<ResponseArrayInterface<IActivityHistory>> {
    const pageRequests = Array.from({ length: paginationCount }, (_, index) =>
      this.loadActivityHistoriesActivityTimeline(params, index + 1, partCount),
    );

    return from(pageRequests).pipe(
      concatMap((request) => request),
      reduce(
        (acc: IActivityHistory[], response: ResponseArrayInterface<IActivityHistory>) => [...acc, ...response.data],
        [] as IActivityHistory[],
      ),
      map(
        (combinedData) =>
          ({
            data: combinedData,
            success: true,
          } as ResponseArrayInterface<IActivityHistory>),
      ),
    );
  }

  private getStationActivityHistoriesWithPagination(
    params: IActivityTimelineRequest,
    paginationCount: number,
    partCount: number,
  ): Observable<GetManyResponseInterface<IStationActivityHistoryCrudResponse>> {
    const pageRequests = Array.from({ length: paginationCount }, (_, index) => {
      const requestParameters: HttpParams = this.getStationActivityHistoryRequestParameters(
        params,
        index + 1,
        partCount,
      );
      return this.stationActivityHistoryService.getManyStationActivityHistories(requestParameters);
    });

    return from(pageRequests).pipe(
      concatMap((request) => request),
      reduce(
        (
          acc: IStationActivityHistoryCrudResponse[],
          response: GetManyResponseInterface<IStationActivityHistoryCrudResponse>,
        ) => [...acc, ...response.data],
        [] as IStationActivityHistoryCrudResponse[],
      ),
      map(
        (combinedData) =>
          ({
            data: combinedData,
            success: true,
          } as GetManyResponseInterface<IStationActivityHistoryCrudResponse>),
      ),
    );
  }

  public getModifiedActivityHistories(
    activityHistories: IActivityHistory[],
    lineData: LineCRUDInterface[],
  ): ActivityTimelineInterface[] {
    return activityHistories.reduce((result: ActivityTimelineInterface[], history: IActivityHistory) => {
      if (!_.isNil(history.activity)) {
        const isMissingTask: boolean =
          _.isNil(history.task) && history.activity.activityType !== ActivityTypes.RUN_TIME;

        const isMissing: boolean =
          (_.isNil(history.workOrder) &&
            history.activity.activityType !== ActivityTypes.IDLE_TIME &&
            !Boolean(history.task?.meta?.withoutWorkOrder)) ||
          (!_.isNil(history.task) && history.task.isMissingData === 1) ||
          isMissingTask;

        result.push({
          isMissing,
          id: history.id,
          lineId: history.lineId,
          lineName: lineData.find((x: LineCRUDInterface) => x.id === history.lineId).title,
          barTitle: isMissing ? this.translate.instant('activityTimeline.missing.data') : history.activity.name,
          taskName: isMissingTask ? this.translate.instant('activityTimeline.missing.task') : history.task?.title,
          activityName: history?.activity?.name,
          activityType: history?.activity?.activityType,
          endDate: moment(history.end).format(mysqlTimestampFormat),
          startDate: moment(history.start).format(mysqlTimestampFormat),
          productDescription: !_.isNil(history.workOrder)
            ? history.workOrder?.product.description
            : _.isNil(history.workOrder) &&
              history.activity.activityType !== ActivityTypes.IDLE_TIME &&
              !Boolean(history.task?.meta?.withoutWorkOrder)
            ? this.translate.instant('activityTimeline.missing.product')
            : ' - ',
          woNumber: !_.isNil(history.workOrder)
            ? history.workOrder?.woNumber
            : _.isNil(history.workOrder) &&
              history.activity.activityType !== ActivityTypes.IDLE_TIME &&
              !Boolean(history.task?.meta?.withoutWorkOrder)
            ? this.translate.instant('activityTimeline.missing.workOrder')
            : ' - ',
          workOrderId: !_.isNil(history.workOrder)
            ? history.workOrder?.id
            : history.activity.activityType !== ActivityTypes.IDLE_TIME &&
              !Boolean(history.task?.meta?.withoutWorkOrder)
            ? this.translate.instant('activityTimeline.missing.workOrder')
            : ' - ',
          duration: ActivityTimelineService.getDurationCalc(history.start, history.end),
          durationUnit: 's',
          isHistory: true,
          isComment: false,
          workOrderComplete: !_.isNil(history.workOrder)
            ? history.workOrder.completed !== null
              ? Boolean(history.workOrder.completed)
              : false
            : false,
          userId: history.userId,
          userName: history.user.userName,
          activitySequenceId: history.activitySequenceId,
          jobId: history.workOrder?.job?.id,
          jobName: history.workOrder?.job?.jobName,
        });
      }

      return result;
    }, []);
  }

  public getModifiedLines(
    lines: IActivityTimeLineLineResponse[],
    timezone: string,
    allLines: LineCRUDInterface[],
  ): ActivityTimelineInterface[] {
    const dateNow: string = moment().tz(timezone).format(mysqlTimestampFormat);

    return lines.reduce((result: ActivityTimelineInterface[], line: IActivityTimeLineLineResponse) => {
      if (!_.isNil(line.activityName)) {
        const isMissingTask: boolean = _.isNil(line.taskName) && line.activityType !== ActivityTypes.RUN_TIME;
        const isMissing: boolean =
          isMissingTask ||
          (_.isNil(line.woNumber) &&
            line.activityType !== ActivityTypes.IDLE_TIME &&
            !Boolean(line.meta?.withoutWorkOrder)) ||
          (!_.isNil(line.taskName) && line.isMissingTask === 1);
        result.push({
          isMissing,
          id: line.id,
          lineId: line.id,
          lineName: line.title,
          barTitle: isMissing ? this.translate.instant('activityTimeline.missing.data') : line.activityName,
          taskName: isMissingTask ? this.translate.instant('activityTimeline.missing.task') : line.taskName,
          activityName: line?.activityName,
          activityType: line?.activityType,
          endDate: dateNow,
          startDate: line.timer,
          productDescription: !_.isNil(line.woNumber)
            ? line.productDescription
            : _.isNil(line.woNumber) &&
              line.activityType !== ActivityTypes.IDLE_TIME &&
              !Boolean(line.meta?.withoutWorkOrder)
            ? this.translate.instant('activityTimeline.missing.product')
            : ' - ',
          woNumber: !_.isNil(line.woNumber)
            ? line.woNumber
            : _.isNil(line.woNumber) &&
              line.activityType !== ActivityTypes.IDLE_TIME &&
              !Boolean(line.meta?.withoutWorkOrder)
            ? this.translate.instant('activityTimeline.missing.workOrder')
            : ' - ',
          workOrderId: line.workOrderId,
          duration: ActivityTimelineService.getDurationCalc(line.timer, dateNow),
          durationUnit: 's',
          isHistory: false,
          isComment: false,
          workOrderComplete: !_.isNil(line.woNumber)
            ? line.completed !== null
              ? Boolean(line.completed)
              : false
            : false,
          jobId: allLines.find((x: LineCRUDInterface) => x.id === line.id)?.currentWorkOrder?.job?.id,
          jobName: allLines.find((x: LineCRUDInterface) => x.id === line.id)?.currentWorkOrder?.job?.jobName,
        });
      }

      return result;
    }, []);
  }

  public getModifiedStationActivityHistories(
    items: IStationActivityHistoryCrudResponse[],
    timezone: string,
    isOngoing: boolean = false,
  ): IActivityTimelineStationActivityHistory[] {
    return items.map((item: IStationActivityHistoryCrudResponse) => {
      const dateNow: string = moment().tz(timezone).format(mysqlTimestampFormat);

      return {
        ...item,
        isOngoing,
        id: `${item.id}_station`,
        resourceId: `${item.lineId}_${item.stationId}_station`,
        woNumber: _.get(item, 'workOrder.woNumber', null),
        isMissingData: ActivityTimelineService.isMissingData(item, isOngoing),
        taskName: ActivityTimelineService.getActivityTaskName(item),
        productDescription: item.workOrder.product.description,
        activityName: item.activity.name,
        jobName: _.get(item, 'workOrder.job.jobName', null),
        startDate: item.startedAt,
        endDate: item.endedAt,
        duration: ActivityTimelineService.getDurationCalc(item.startedAt, item.endedAt ? item.endedAt : dateNow),
        name: item.activity.name,
        barTitle: item.activity.name,
        withoutWorkOrder: item.task?.meta?.withoutWorkOrder === 1,
      };
    });
  }

  private getLinesActivityTimelineData(
    params: IActivityTimelineRequest,
  ): Observable<ResponseArrayInterface<IActivityTimeLineLineResponse>> {
    const httpParams: HttpParams = new HttpParams()
      .append('siteId', String(params.siteId))
      .append('lineIds', String(params.lineId))
      .append('startDate', params.startDate)
      .append('endDate', params.endDate)
      .append('isBusinessDate', params.isBusinessDate);

    return this.http.get<ResponseArrayInterface<IActivityTimeLineLineResponse>>(
      `${this.routes.lines}/activity-timeline`,
      {
        params: httpParams,
      },
    );
  }

  private getStationActivityHistoryRequestParameters(
    objectData: IActivityTimelineRequest,
    page: number,
    limit: number,
    isOngoing: boolean = false,
  ): HttpParams {
    return new HttpParams()
      .append('page', String(page))
      .append('limit', String(limit))
      .append('join', 'activity')
      .append('join', 'workOrder')
      .append('join', 'workOrder.product')
      .set(
        's',
        JSON.stringify({
          $and: [
            { stationId: { $in: objectData.stationIds } },
            ...(isOngoing ? [] : [{ startedAt: { $lt: moment(objectData.endDate).format(mysqlDateFormat) } }]),
            { endedAt: isOngoing ? { $isnull: true } : { $gt: moment(objectData.startDate).format(mysqlDateFormat) } },
          ],
        }),
      );
  }

  private static getDurationCalc(startDate: string, endDate: string): number {
    return moment.duration(moment(endDate).diff(moment(startDate))).asSeconds();
  }

  private static isMissingData(item: IStationActivityHistoryCrudResponse, isOngoing: boolean = false): boolean {
    return (
      (item.activity?.activityType !== ActivityTypes.RUN_TIME && (item.taskId === null || item.task?.isMissingData)) ||
      item.activityId === null ||
      item.startedAt === null ||
      item.startedAt === '' ||
      (!isOngoing && (item.endedAt === '' || item.endedAt === null)) ||
      (item.activity?.activityType !== ActivityTypes.IDLE_TIME && item.workOrderId === null)
    );
  }

  private static getActivityTaskName(item: IStationActivityHistoryCrudResponse): string {
    if (item.activity?.activityType === ActivityTypes.RUN_TIME) {
      return _.get(item, 'workOrder.product.description', '');
    }

    return _.get(item, 'task.title', null);
  }
}
