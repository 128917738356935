import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  EAxisNameLocation,
  EBarChartGroupedDataLabel,
  IBarChartConfiguration,
  IBarChartDataItemStyle,
  IBarChartTitleStyle,
  IChartEventParams,
  IChartLoader,
  IDateRangeOptions,
  IGenericChartData,
  IPreparedData,
  TargetCategory,
} from './tasks-charts.model';
import * as echarts from 'echarts';
import * as _ from 'lodash';
import {
  IActivityLog,
  IRootCauseAnalysisChartNode,
} from '../../../store/reports/root-cause-analysis/root-cause-analysis.model';
import { activityColors } from '../../helper/app-helper';
import moment from 'moment';
import { take } from 'rxjs/operators';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { getCurrentDateTimeAsMoment, mysqlTimestampFormat, transformDurationType } from '../../helper/date';
import { TranslateService } from '@ngx-translate/core';
import { ActivityTypes } from '../../model/enum/activity-types';
import { User } from '../../../store/user/model';
import { ScwMatButtonGroupButtons } from '../scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import { CustomColors } from '../../service/color/color.model';
import { DECIMAL_NUMERIC_SCALE } from '../../../../constants';
import { TasksChartsConstants } from './tasks-charts.constants';
import { TasksChartsStore } from './tasks-charts.store';
import { Observable, Subscription } from 'rxjs';
import * as RootCauseAnalysisActions from '../../../store/reports/root-cause-analysis/root-cause-analysis.actions';
import { ToastHelperService } from '../../service/toast/toast.helper.service';
import { ofType } from '@ngrx/effects';
import * as PageHeaderActions from '../../../store/page-header/page-header.actions';
import { ISelectGeneric } from '../scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { EActivityEquipmentTaskChart } from '../../../view/reports/root-cause-analysis/charts/charts.model';

@Component({
  selector: 'app-tasks-charts',
  templateUrl: './tasks-charts.component.html',
  styleUrls: ['./tasks-charts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TasksChartsStore],
})
export class TasksChartsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('activityChartFrame') public activityChartFrame: ElementRef;
  @ViewChild('equipmentChartFrame') public equipmentChartFrame: ElementRef;

  @Input() public rawData: IActivityLog[] = [];
  @Input() public chartData: IRootCauseAnalysisChartNode[];
  @Input() public customColors: CustomColors;
  @Input() public isDataLoading: boolean = true;
  @Input() public isBusinessDate: boolean = false;
  @Input() public applyFilterOnTaskChartClick: boolean = true;
  @Input() public dateRange: IDateRangeOptions = {
    start: null,
    end: null,
  };
  @Input() public chartConfiguration: IBarChartConfiguration;
  @Input() public isChartsVertical: boolean = false;
  @Input() public initialChartOrientation: EActivityEquipmentTaskChart = EActivityEquipmentTaskChart.BAR;
  @Output() noEquipmentUsedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() noTaskUsedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() runTimeActivitySelectedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setVerticalModeAvailable: EventEmitter<boolean> = new EventEmitter<boolean>();

  public readonly allowedActions$: Observable<{ decrement: boolean; increment: boolean }> =
    this.tasksChartsStore.allowedActions$;
  public readonly activityChartHeight$: Observable<number> = this.tasksChartsStore.activityChartHeight$;
  public readonly equipmentChartHeight$: Observable<number> = this.tasksChartsStore.equipmentChartHeight$;
  public readonly topChartsMaxAllowedHeight$: Observable<number> = this.tasksChartsStore.topChartsMaxAllowedHeight$;
  public readonly heightLevelsNotNeeded$: Observable<boolean> = this.tasksChartsStore.heightLevelsNotNeeded$;

  public readonly chartHeightButtonGroup: ScwMatButtonGroupButtons[] = [
    { iconClass: 'fa fa-caret-up', value: false },
    { iconClass: 'fa fa-caret-down', value: true },
  ];

  public showTargets: boolean = false;
  public activitiesChartName: string;
  public equipmentChartName: string;
  public readonly taskTargetCategoryOptions: ISelectGeneric<TargetCategory, string>[] = [
    {
      name: this.translate.instant('rootCauseAnalysis.taskTargetCategoryOptions.none'),
      id: TargetCategory.none,
    },
    {
      name: this.translate.instant('rootCauseAnalysis.taskTargetCategoryOptions.workOrder'),
      id: TargetCategory.workOrder,
    },
    {
      name: this.translate.instant('rootCauseAnalysis.taskTargetCategoryOptions.dayAndShift'),
      id: TargetCategory.dayAndShift,
    },
    {
      name: this.translate.instant('rootCauseAnalysis.taskTargetCategoryOptions.businessDay'),
      id: TargetCategory.businessDay,
    },
  ];
  public selectedTaskTargetCategory: ISelectGeneric<TargetCategory, string>[] = [
    {
      name: this.translate.instant('rootCauseAnalysis.taskTargetCategoryOptions.none'),
      id: TargetCategory.none,
    },
  ];
  public activityChartAllData: IGenericChartData[] = [];

  private readonly activityTypeTexts: string[] = [
    ActivityTypes.DOWN_TIME_PLANNED,
    ActivityTypes.DOWN_TIME,
    ActivityTypes.IDLE_TIME,
    ActivityTypes.RUN_TIME,
  ];
  private readonly shortHour: string = this.translate.instant('general.shortHour');
  private readonly noEquipmentAssignedLabel: string = this.translate.instant(
    'rootCauseAnalysis.charts.equipment.noEquipmentAssigned.label',
  );
  private readonly othersLabel: string = this.translate.instant('rootCauseAnalysis.charts.tasks.others.label');
  private readonly tooltipActivityTitle: string = this.translate.instant(
    'rootCauseAnalysis.charts.tasks.tooltip.activityName',
  );
  private readonly tooltipEquipmentTitle: string = this.translate.instant(
    'rootCauseAnalysis.charts.tasks.tooltip.equipmentName',
  );
  private readonly tooltipTaskTitle: string = this.translate.instant('rootCauseAnalysis.charts.tasks.tooltip.name');
  private readonly tooltipDurationTitle: string = this.translate.instant(
    'rootCauseAnalysis.charts.tasks.tooltip.value',
  );
  private readonly tooltipActualDurationTranslation: string = this.translate.instant(
    'reports.rootCauseAnalysis.charts.task.tooltip.actualDuration',
  );
  private readonly tooltipTargetDurationTranslation: string = this.translate.instant(
    'reports.rootCauseAnalysis.charts.task.tooltip.targetDuration',
  );
  private readonly missingDataTranslation: string = this.translate.instant('general.missingDataText');
  private readonly seriesFirstElementData: string = 'series.0.data';
  private readonly fourDecimals: number = 4;
  private readonly twoDecimals: number = 2;
  private readonly barLabelColor: string = '#4a4a4a';
  private readonly zeroString: string = '0';
  private activitiesChartRef: echarts.ECharts;
  private equipmentChartRef: echarts.ECharts;
  private tasksChartRef: echarts.ECharts;
  private activityGroups: IActivityLog[][];
  private equipmentGroups: IActivityLog[][];
  private taskGroups: IActivityLog[][];
  private selectedActivityId: number = null;
  private selectedEquipmentId: number = null;
  private selectedEquipmentBarId: number = null;
  private selectedActivityName: string;
  private selectedEquipmentName: string;
  private selectedTaskNames: string[] = [];
  private previousResizeTaskDataLength: number = 0;
  private timezone$: string;
  private formattedData: IActivityLog[];
  private leftPercentageActivitiesChart: string;
  private leftPercentageEquipmentChart: string;
  private leftPercentageTasksChart: string;
  public activitiesChartOption: echarts.EChartsOption;
  public equipmentChartOption: echarts.EChartsOption;
  public tasksChartOption: echarts.EChartsOption;
  public isEquipmentUsedInTasks: boolean;
  public isTaskUsedInActivities: boolean;
  public isNoEquipmentAssignedExists: boolean = false;
  public isNoEquipmentOnly: boolean;
  public showOnlyActivitiesChart: boolean = false;
  public modalHeader: string = this.translate.instant(
    'rootCauseAnalysis.taskTargetCategoryInformationModal.modalHeader',
  );

  public modalData: { header: string; messages: string[] }[] = [
    {
      header: this.translate.instant('rootCauseAnalysis.taskTargetCategoryOptions.none'),
      messages: [
        this.translate.instant('rootCauseAnalysis.taskTargetCategoryInformationModal.none.description'),
        this.translate.instant('rootCauseAnalysis.taskTargetCategoryInformationModal.none.formula'),
      ],
    },
    {
      header: this.translate.instant('rootCauseAnalysis.taskTargetCategoryOptions.workOrder'),
      messages: [
        this.translate.instant('rootCauseAnalysis.taskTargetCategoryInformationModal.workOrder.description'),
        this.translate.instant('rootCauseAnalysis.taskTargetCategoryInformationModal.workOrder.formula'),
      ],
    },
    {
      header: this.translate.instant('rootCauseAnalysis.taskTargetCategoryOptions.dayAndShift'),
      messages: [
        this.translate.instant('rootCauseAnalysis.taskTargetCategoryInformationModal.dayAndShift.description'),
        this.translate.instant('rootCauseAnalysis.taskTargetCategoryInformationModal.dayAndShift.formula'),
      ],
    },
    {
      header: this.translate.instant('rootCauseAnalysis.taskTargetCategoryOptions.businessDay'),
      messages: [
        this.translate.instant('rootCauseAnalysis.taskTargetCategoryInformationModal.businessDay.description'),
        this.translate.instant('rootCauseAnalysis.taskTargetCategoryInformationModal.businessDay.formula'),
      ],
    },
  ];

  private readonly defaultChartConfiguration: IBarChartConfiguration = {
    highlightStyle: {
      borderColor: '#FFFFFF',
      shadowColor: '#656565',
      borderWidth: 2,
      shadowBlur: 2.5,
    },
    maxBarWidth: 50,
    activitiesChartMaxBarsWithoutOther: 500,
    equipmentChartMaxBarsWithoutOther: 500,
    tasksChartMaxBarsWithoutOther: 500,
    shouldResizeTasksChart: false,
    activitiesChartDivClasses: 'col',
    equipmentChartDivClasses: 'col',
    tasksChartDivClasses: 'col',
    shouldDisplayInfoMessages: false,
    barLabelPositionThreshold: 0.1,
    leftPercentageActivitiesChart: '0%',
    leftPercentageEquipmentChart: '0%',
    leftPercentageTasksChart: '0%',
    rightPercentage: '10%',
    showNamesInBars: false,
    noEquipmentAssignedLabelHasStar: false,
    showActivitiesChartYAxisName: true,
    showEquipmentChartYAxisName: true,
    yAxisStyle: {
      nameFontSize: 20,
      nameLocation: EAxisNameLocation.END,
      namePadding: [0, 0, 0, 0],
      labelMaxLength: 100,
      showLabels: true,
      showName: true,
    },
    xAxisStyle: {
      nameFontSize: 12,
      nameLocation: EAxisNameLocation.END,
      namePadding: [0, 0, 0, 0],
      showLabels: true,
      showName: true,
    },
  };

  public showNoEquipment: boolean = false;
  public isPrinting: boolean = false;
  private resizeObserver: ResizeObserver;
  private subscriptions: Subscription[] = [];
  private isChartDataChanged: boolean = false;
  private chartOptionsMap: string[] = ['activitiesChartOption', 'equipmentChartOption', 'tasksChartOption'];
  private selectedActivityItemStyle: IBarChartDataItemStyle = null;
  private selectedEquipmentItemStyle: IBarChartDataItemStyle = null;
  private selectedTaskItemStyle: IBarChartDataItemStyle = null;
  private selectedTaskId: number = null;

  constructor(
    private readonly host: ElementRef,
    private readonly store: Store<OeeAppState>,
    private readonly actionSubject: ActionsSubject,
    private readonly tasksChartsStore: TasksChartsStore,
    private readonly translate: TranslateService,
    private readonly decimalHelper: DecimalHelper,
    private readonly toastHelperService: ToastHelperService,
  ) {}

  public ngOnInit(): void {
    this.setTimezone();
    this.mergeChartConfigurations();
    this.setupTopCharts();
    this.createResizeObserver();

    this.subscriptions.push(
      this.actionSubject.pipe(ofType(PageHeaderActions.RUN_PRINT_FUNCTIONALITY)).subscribe(() => {
        if (
          this.chartConfiguration.showActivitiesChartYAxisName ||
          this.chartConfiguration.showEquipmentChartYAxisName ||
          this.isPrinting
        ) {
          return;
        }

        this.isPrinting = true;

        this.setTopChartGridTop(TasksChartsConstants.taskChartTopPadding, false);
        this.store.dispatch(new RootCauseAnalysisActions.RunRegularModePrintFunctionality());
      }),
      this.actionSubject.pipe(ofType(PageHeaderActions.PRINT_COMPLETED)).subscribe(() => {
        if (
          this.chartConfiguration.showActivitiesChartYAxisName ||
          this.chartConfiguration.showEquipmentChartYAxisName
        ) {
          return;
        }

        this.setTopChartGridTop(TasksChartsConstants.topChartsTopPadding, true);

        this.isPrinting = false;
      }),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('initialChartOrientation')) {
      this.prepareChartOptions();
    }
    if (changes.hasOwnProperty('isDataLoading')) {
      this.setChartLoadingState(this.isDataLoading);
    }

    if (this.isDataLoading) {
      return;
    }

    if (changes.hasOwnProperty('chartConfiguration')) {
      this.mergeChartConfigurations();
      this.setChartLayout();
    }

    if (changes.hasOwnProperty('isChartsVertical')) {
      this.changeChartsOrientation();
    }

    if (changes.hasOwnProperty('rawData') || changes.hasOwnProperty('chartData')) {
      const data: IRootCauseAnalysisChartNode[] | IActivityLog[] = this.chartData ? this.chartData : this.rawData;
      this.isChartDataChanged = true;

      if (!this.timezone$) {
        this.setTimezone();
      }

      if (!data || data.length === 0 || !this.timezone$) {
        this.activitiesChartOption = null;
        this.equipmentChartOption = null;
        this.tasksChartOption = null;
        this.activityChartAllData = [];

        return;
      }

      this.clearSelectedChartData();
      this.showOnlyActivitiesChart = false;
      this.setRootCauseAnalysisSelectedChartData(false);
      this.checkForEquipmentAndTaskUsage();
      this.formatData();
      this.setEquipmentChartFrameScroll(true);
      this.scrollActivityChartToTop();
      this.prepareChartOptions();
      this.setChartLoadingState(false);
      this.isChartDataChanged = false;
    }
  }

  public toggleTaskChartTargets(): void {
    const { data } = this.tasksChartOption.series?.[0];

    if (!data) {
      return;
    }

    const redColor: string = 'rgb(221, 51, 43, 255)';
    const greenColor: string = 'rgb(69, 198, 62, 255)';
    const markPoint: echarts.MarkPointComponentOption = {
      symbolSize: 0,
      data: data.reduce(
        (acc, { originalName, targetDuration, value, taskId, targetDurationFormula, isOthers }, index: number) => {
          if (isOthers || !taskId) {
            acc.push({ coord: [] });

            return acc;
          }

          const targetDurationFormatted: string = targetDuration ?? this.zeroString;
          const withinTarget: boolean = this.decimalHelper.isLessThan(String(value), targetDurationFormatted);
          const color: string = withinTarget ? greenColor : redColor;

          acc.push(
            {
              originalName,
              coord: [
                !this.isChartsVertical ? targetDurationFormatted : index,
                !this.isChartsVertical ? index : targetDurationFormatted,
              ],
              label: {
                backgroundColor: color,
                borderWidth: 2,
                borderRadius: 4,
                borderColor: 'white',
                padding: [20, 2],
                distance: 0,
                rotate: this.isChartsVertical ? 90 : 0,
              },
              tooltip: {
                borderColor: color,
                formatter: (
                  params: echarts.TooltipComponentFormatterCallbackParams & { data: { originalName: string } },
                ): string => this.getTargetTooltipContent(data, params, targetDurationFormula),
              },
            },
            !this.isChartsVertical
              ? {
                  originalName,
                  coord: [
                    !this.isChartsVertical ? targetDurationFormatted : index,
                    !this.isChartsVertical ? index : targetDurationFormatted,
                  ],
                  label: {
                    color: color,
                    offset: [0, 30],
                    formatter: (): string =>
                      `${this.decimalHelper.toFixedValue(targetDurationFormatted, this.twoDecimals)} ${this.shortHour}`,
                  },
                  emphasis: { disabled: true },
                  tooltip: {
                    borderColor: color,
                    formatter: (
                      params: echarts.TooltipComponentFormatterCallbackParams & { data: { originalName: string } },
                    ): string => this.getTargetTooltipContent(data, params, targetDurationFormula),
                  },
                }
              : {},
          );

          return acc;
        },
        [],
      ),
    };
    this.tasksChartOption = {
      ...this.tasksChartOption,
      series: [
        {
          ...this.tasksChartOption.series[0],
          data: this.decideBarLabelPosition(data, true),
          markPoint: this.showTargets ? markPoint : undefined,
        },
      ],
    };

    this.calculateAndSetChartXAxisMaximumValue();
  }

  private getTargetTooltipContent(
    data,
    params: echarts.TooltipComponentFormatterCallbackParams & {
      data: { originalName: string };
    },
    targetDurationFormula,
  ): string {
    const value: number = data.find(
      (row: IGenericChartData): boolean => row.originalName === params.data.originalName,
    )?.value;

    if (!value) {
      return '';
    }

    return `
      <b>${this.tooltipActualDurationTranslation}: </b>
      ${this.decimalHelper.toFixedValue(String(value))} ${this.shortHour}
      <br/>
      <b>${this.tooltipTargetDurationTranslation}: </b> ${targetDurationFormula}
    `;
  }

  public setTopChartsHeightLevel(increment: boolean): void {
    this.resizeObserver.observe(this.host.nativeElement.querySelector('.height-level-button-container'));

    if (increment) {
      this.tasksChartsStore.incrementTopChartsHeightLevel();

      return;
    }

    this.tasksChartsStore.decrementTopChartsHeightLevel();
  }

  private setupTopCharts(): void {
    this.allowedActions$.subscribe(({ decrement, increment }) => {
      this.chartHeightButtonGroup[0].disabled = !decrement;
      this.chartHeightButtonGroup[1].disabled = !increment;
    });
  }

  private createResizeObserver(): void {
    this.resizeObserver = new ResizeObserver((resizeObserverEntry: ResizeObserverEntry[]) => {
      if (!resizeObserverEntry?.length) {
        return;
      }

      resizeObserverEntry[0].target.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      this.resizeObserver.unobserve(resizeObserverEntry[0].target);
    });
  }

  private setTimezone(): void {
    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User) => {
        if (state.isUserLoaded && !state.isUserLoading) {
          this.timezone$ = state.timezone;
        }
      });
  }

  public onActivitiesChartClick(params: unknown): void {
    const eventParams: IChartEventParams = params as IChartEventParams;
    this.clearSelectedChartData();

    _.get(this.activitiesChartOption, this.seriesFirstElementData, []).forEach(
      (data: IGenericChartData, index: number) => {
        if (index !== eventParams.dataIndex) {
          data.itemStyle = { color: data.itemStyle.color };

          return;
        }

        this.showOnlyActivitiesChart = false;

        if (data.itemStyle.borderColor) {
          data.itemStyle = { color: data.itemStyle.color };

          this.prepareEquipmentChartOption();
          this.prepareTasksChartOption();
          this.runTimeActivitySelectedEvent.emit(false);

          return;
        }

        data.itemStyle = { ...data.itemStyle, ...this.chartConfiguration.highlightStyle };
        this.selectedActivityItemStyle = data.itemStyle;
        this.selectedActivityId = data.activityId;
        this.selectedActivityName = data.originalName;

        if (data.activityType === ActivityTypes.RUN_TIME) {
          this.showOnlyActivitiesChart = true;

          this.runTimeActivitySelectedEvent.emit(true);

          return;
        }

        this.runTimeActivitySelectedEvent.emit(false);

        if (data.name === EBarChartGroupedDataLabel.OTHERS) {
          this.prepareEquipmentChartOption(data.activityIds);
          this.prepareTasksChartOption(data.activityIds);
        } else {
          this.prepareEquipmentChartOption(data.activityId);
          this.prepareTasksChartOption(data.activityId);
        }

        this.setBarColorsToActivityTypeColor(data.itemStyle.color, this.equipmentChartOption);
        this.setTaskBarColorsToActivityTypeColor(data.itemStyle.color, this.tasksChartOption);
      },
    );

    this.resetEquipmentChartHighlight();

    if (!this.activitiesChartRef?.isDisposed()) {
      this.activitiesChartRef?.setOption(this.activitiesChartOption);
    }

    if (!this.equipmentChartRef?.isDisposed()) {
      this.equipmentChartRef?.setOption(this.equipmentChartOption);
    }

    if (!this.tasksChartRef?.isDisposed()) {
      this.tasksChartRef?.setOption(this.tasksChartOption);
    }

    this.noEquipmentUsedEvent.emit(this.isNoEquipmentOnly);

    this.setRootCauseAnalysisSelectedChartData();

    if (this.isChartsVertical) {
      this.chartOptionsMap.forEach((option: string) => {
        if (option === 'tasksChartOption') {
          return;
        }
        this[option].xAxis.data.reverse();
      });
    }
  }

  public onEquipmentChartClick(params: unknown): void {
    const eventParams: IChartEventParams = params as IChartEventParams;

    this.selectedEquipmentId = null;
    this.selectedEquipmentBarId = this.selectedEquipmentBarId === eventParams.dataIndex ? null : eventParams.dataIndex;
    this.selectedEquipmentName = undefined;
    this.selectedTaskNames = [];

    _.get(this.equipmentChartOption, this.seriesFirstElementData, []).forEach(
      (data: IGenericChartData, index: number) => {
        if (index !== eventParams.dataIndex) {
          data.itemStyle = { color: data.itemStyle.color };

          return;
        }

        if (data.itemStyle.borderColor) {
          data.itemStyle = { color: data.itemStyle.color };

          this.prepareTasksChartOption(this.selectedActivityId);

          return;
        }

        data.itemStyle = { ...data.itemStyle, ...this.chartConfiguration.highlightStyle };
        this.selectedEquipmentItemStyle = data.itemStyle;

        this.selectedEquipmentName =
          data.name === EBarChartGroupedDataLabel.NO_EQUIPMENT_ASSIGNED ? null : data.originalName;
        this.selectedEquipmentId = data.equipmentId;

        if (data.name === EBarChartGroupedDataLabel.OTHERS) {
          this.prepareTasksChartOption(data.activityIds, data.equipmentIds);

          return;
        }

        this.prepareTasksChartOption(this.selectedActivityId, data.equipmentId);
      },
    );

    if (!this.equipmentChartRef?.isDisposed()) {
      this.equipmentChartRef?.setOption(this.equipmentChartOption);
    }

    if (!this.tasksChartRef?.isDisposed()) {
      this.tasksChartRef?.setOption(this.tasksChartOption);
    }

    this.setRootCauseAnalysisSelectedChartData();
  }

  public onTasksChartClick(params: unknown): void {
    this.selectedTaskId = null;

    if (!this.applyFilterOnTaskChartClick) {
      return;
    }

    const eventParams: IChartEventParams = params as IChartEventParams;
    this.selectedTaskNames = [];

    _.get(this.tasksChartOption, this.seriesFirstElementData, []).forEach((data: IGenericChartData, index: number) => {
      if (index !== eventParams.dataIndex) {
        data.itemStyle = { color: data.itemStyle.color };

        return;
      }

      if (data.itemStyle.borderColor) {
        data.itemStyle = { color: data.itemStyle.color };

        return;
      }

      data.itemStyle = { ...data.itemStyle, ...this.chartConfiguration.highlightStyle };
      this.selectedTaskItemStyle = data.itemStyle;

      this.selectedTaskNames =
        data.name === EBarChartGroupedDataLabel.OTHERS ? this.getTaskNamesExcludingOthers() : [data.originalName];

      this.selectedTaskId = data.taskId;
    });

    this.setRootCauseAnalysisSelectedChartData();
  }

  public setActivitiesBarChartRef(params: echarts.ECharts): void {
    this.activitiesChartRef = params;
  }

  public setTasksBarChartRef(params: echarts.ECharts): void {
    this.tasksChartRef = params;
    this.previousResizeTaskDataLength = 0;
    this.decideTasksBarChartHeight(_.get(this.tasksChartOption, this.seriesFirstElementData, []).length);
  }

  private setBarColorsToActivityTypeColor(color: string, chartOption: echarts.EChartsOption): void {
    _.get(chartOption, this.seriesFirstElementData, []).forEach((data: IGenericChartData) => {
      data.itemStyle.color = color;
    });
  }

  private setTaskBarColorsToActivityTypeColor(color: string, chartOption: echarts.EChartsOption): void {
    _.get(chartOption, this.seriesFirstElementData, []).forEach((data: IGenericChartData) => {
      data.itemStyle.color = data.isMissingData ? data.itemStyle.color : color;
    });
  }

  private prepareChartOptions(): void {
    const defaultGenericBarChartSeries: echarts.BarSeriesOption = {
      type: 'bar',
      label: {
        show: true,
        formatter: (item: echarts.DefaultLabelFormatterCallbackParams) => {
          const numberOfDecimals: number = TasksChartsComponent.getNumberOfDecimals(Number(item.value));
          const name: string =
            item.name === EBarChartGroupedDataLabel.NO_EQUIPMENT_ASSIGNED
              ? this.noEquipmentAssignedLabel
              : item.name === EBarChartGroupedDataLabel.OTHERS
              ? this.othersLabel
              : item.name;

          return this.chartConfiguration.showNamesInBars
            ? `${name} - ${this.decimalHelper.toFixedValue(item.value.toString(), numberOfDecimals)} ${this.shortHour}`
            : `${this.decimalHelper.toFixedValue(item.value.toString(), numberOfDecimals)} ${this.shortHour}`;
        },
        position: 'insideRight',
      },
      barMaxWidth: this.chartConfiguration.maxBarWidth,
    };
    const defaultGenericBarChartOption: echarts.EChartsOption = {
      xAxis: {
        type: 'value',
        name: this.translate.instant('rootCauseAnalysis.charts.hours'),
        nameLocation: this.chartConfiguration.xAxisStyle.nameLocation,
        nameTextStyle: {
          fontSize: this.chartConfiguration.xAxisStyle.nameFontSize,
          padding: this.chartConfiguration.xAxisStyle.namePadding,
        },
        axisLabel: {
          show: this.chartConfiguration.xAxisStyle.showLabels,
        },
      },
      series: [defaultGenericBarChartSeries],
    };
    this.activitiesChartOption = _.cloneDeep(defaultGenericBarChartOption);
    this.equipmentChartOption = _.cloneDeep(defaultGenericBarChartOption);
    this.tasksChartOption = _.cloneDeep({
      ...defaultGenericBarChartOption,
      series: [
        {
          ...defaultGenericBarChartSeries,
          ...(this.chartConfiguration.taskChartBarWidth
            ? { barWidth: this.chartConfiguration.taskChartBarWidth }
            : { barMaxWidth: this.chartConfiguration.maxBarWidth }),
        },
      ],
    });

    this.prepareActivitiesChartOption();
    this.prepareEquipmentChartOption();
    this.prepareTasksChartOption();
    this.setVerticalModeAvailable.emit(true);

    if (this.isChartsVertical) {
      this.changeChartsOrientation();
    }
  }

  private groupActivityLogData(): void {
    this.activityGroups = _.groupBy(this.formattedData, 'activityId');
    this.equipmentGroups = _.groupBy(this.formattedData, 'equipmentId');
    this.taskGroups = _.groupBy(this.formattedData, 'taskName');
  }

  private formatData(): void {
    this.formattedData = this.rawData.map((logData: IActivityLog) => {
      const oldEndDate: string =
        logData.end && logData.end !== 'Ongoing'
          ? logData.end
          : getCurrentDateTimeAsMoment(this.timezone$).format(mysqlTimestampFormat);
      const newStartDate: string =
        this.dateRange.start && moment(logData.start).isBefore(this.dateRange.start) && !this.isBusinessDate
          ? this.dateRange.start.format(mysqlTimestampFormat)
          : logData.start;
      const newEndDate: string =
        this.dateRange.end && moment(oldEndDate).isAfter(this.dateRange.end) && !this.isBusinessDate
          ? this.dateRange.end.format(mysqlTimestampFormat)
          : oldEndDate;

      const formattedTask = this.getFormattedTaskName({
        taskId: logData.taskId,
        activityType: logData.activityTypeText,
        taskName: logData.taskName,
        activityColor: logData.activityColor,
      });

      return {
        ...logData,
        start: newStartDate,
        end: newEndDate,
        taskId: logData.taskId ?? 0,
        taskName: formattedTask.taskName,
        productId: logData.productId ?? 'N/A',
        duration: transformDurationType(moment(newEndDate).diff(moment(newStartDate)), 'millisecond', 'second'),
      };
    });

    this.groupActivityLogData();
  }

  private prepareActivitiesChartOption(): void {
    let activitiesChartData: IGenericChartData[] = this.prepareActivitiesChartData();
    activitiesChartData = activitiesChartData.filter((activityData: IGenericChartData) => activityData);
    activitiesChartData = this.groupExtraChartData(
      activitiesChartData,
      this.chartConfiguration.activitiesChartMaxBarsWithoutOther,
    );
    activitiesChartData = this.decideBarLabelPosition(activitiesChartData);
    const chartNameWithCount: string = this.translate.instant('general.charts.activity.title.withCount', {
      count: this.chartConfiguration.equipmentChartMaxBarsWithoutOther,
    });
    const chartName: string =
      activitiesChartData.length > this.chartConfiguration.activitiesChartMaxBarsWithoutOther
        ? chartNameWithCount
        : this.translate.instant('general.charts.activity.title.normal');
    this.activitiesChartName = chartName;
    this.activitiesChartOption.series[0].data = _.clone(activitiesChartData);
    this.activitiesChartOption.grid = {
      ...this.activitiesChartOption.grid,
      top: this.chartConfiguration.showActivitiesChartYAxisName
        ? TasksChartsConstants.taskChartTopPadding
        : TasksChartsConstants.topChartsTopPadding,
    };

    this.prepareChartYAxisOptions(this.activitiesChartOption, chartName);
    this.prepareChartLayoutOptions(
      this.activitiesChartOption,
      chartName,
      this.tooltipActivityTitle,
      this.leftPercentageActivitiesChart,
      this.chartConfiguration.activitiesChartTitleStyle,
    );
    this.tasksChartsStore.setActivityBarCount(this.activitiesChartOption.series[0].data.length);
    this.activityChartAllData = activitiesChartData;
  }

  private prepareActivitiesChartData(): IGenericChartData[] {
    if (!this.chartData) {
      const { data, maxDuration } = this.prepareActivitiesChartDataFromRawData();
      this.setChartXAxisFormatter(this.activitiesChartOption, maxDuration);

      return data;
    }

    let maxDuration: number = 0;
    const chartData: IGenericChartData[] = [];
    Object.values(_.groupBy(this.chartData, 'activityId')).forEach((group: IRootCauseAnalysisChartNode[]) => {
      const node: IRootCauseAnalysisChartNode = _.first(group);
      const duration: number = _.sumBy(group, 'duration');
      const durationInHours: number = transformDurationType(duration, 'seconds', 'hours', this.fourDecimals);

      if (durationInHours === 0) {
        return;
      }

      maxDuration = Math.max(durationInHours, maxDuration);

      chartData.push({
        activityId: node.activityId,
        originalName: node.activityName,
        activityType: node.activityType,
        name: _.truncate(node.activityName, {
          length: this.chartConfiguration.yAxisStyle.labelMaxLength,
        }),
        value: durationInHours,
        itemStyle: { color: node.activityColor ?? activityColors(node.activityType) },
      });
    });

    this.setChartXAxisFormatter(this.activitiesChartOption, maxDuration);

    return chartData;
  }

  private prepareEquipmentChartOption(activityId?: number | number[]): void {
    if (this.isChartsVertical && !this.isChartDataChanged) {
      this.changeChartsOrientation();
    }

    let equipmentChartData: IGenericChartData[] = this.prepareEquipmentChartData(activityId);
    equipmentChartData = equipmentChartData.filter((equipmentData: IGenericChartData) => equipmentData);
    equipmentChartData = this.groupExtraChartData(
      equipmentChartData,
      this.chartConfiguration.equipmentChartMaxBarsWithoutOther,
    );
    this.isNoEquipmentOnly = !equipmentChartData.some(
      (equipmentData: IGenericChartData) => equipmentData.equipmentId !== null,
    );
    equipmentChartData = this.decideBarLabelPosition(equipmentChartData);
    const chartNameWithCount: string = this.translate.instant('general.charts.equipment.title.withCount', {
      count: this.chartConfiguration.equipmentChartMaxBarsWithoutOther,
    });
    const chartName: string =
      equipmentChartData.length > this.chartConfiguration.equipmentChartMaxBarsWithoutOther
        ? chartNameWithCount
        : this.translate.instant('general.charts.equipment.title.normal');
    this.equipmentChartName = chartName;
    this.equipmentChartOption.series[0].data = _.clone(equipmentChartData);
    this.equipmentChartOption.grid = {
      ...this.equipmentChartOption.grid,
      top: this.chartConfiguration.showEquipmentChartYAxisName
        ? TasksChartsConstants.taskChartTopPadding
        : TasksChartsConstants.topChartsTopPadding,
    };

    this.prepareChartYAxisOptions(this.equipmentChartOption, chartName);
    this.prepareChartLayoutOptions(
      this.equipmentChartOption,
      chartName,
      this.tooltipEquipmentTitle,
      this.leftPercentageEquipmentChart,
      this.chartConfiguration.equipmentChartTitleStyle,
    );
    this.tasksChartsStore.setEquipmentBarCount(this.equipmentChartOption.series[0].data.length);

    if (this.isChartsVertical && !this.isChartDataChanged) {
      this.changeChartsOrientation();
      //@ts-ignore
      this.equipmentChartOption.xAxis?.data?.reverse();
    }
  }

  private prepareEquipmentChartData(activityId?: number | number[]): IGenericChartData[] {
    if (!this.chartData) {
      const { data, maxDuration } = this.prepareEquipmentChartDataFromRawData(activityId);
      this.setChartXAxisFormatter(this.equipmentChartOption, maxDuration);

      return data;
    }

    let maxDuration: number = 0;
    const chartData: IGenericChartData[] = [];
    const filteredData: IRootCauseAnalysisChartNode[] = activityId
      ? this.chartData.filter((equipment: IRootCauseAnalysisChartNode) =>
          Array.isArray(activityId) ? activityId.includes(equipment.activityId) : equipment.activityId === activityId,
        )
      : this.chartData;

    Object.values(_.groupBy(filteredData, 'equipmentName')).forEach((group: IRootCauseAnalysisChartNode[]) => {
      const node: IRootCauseAnalysisChartNode = _.first(group);
      const duration: number = _.sumBy(group, 'duration');
      const durationInHours: number = transformDurationType(duration, 'seconds', 'hours', this.fourDecimals);

      if (durationInHours === 0) {
        return;
      }

      maxDuration = Math.max(durationInHours, maxDuration);

      if (node.equipmentName || this.showNoEquipment) {
        chartData.push({
          activityId: node.activityId,
          equipmentId: node.equipmentId,
          originalName:
            node.equipmentName ??
            `${this.noEquipmentAssignedLabel}${this.chartConfiguration.noEquipmentAssignedLabelHasStar ? '*' : ''}`,
          name: node.equipmentName
            ? _.truncate(node.equipmentName, { length: this.chartConfiguration.yAxisStyle.labelMaxLength })
            : EBarChartGroupedDataLabel.NO_EQUIPMENT_ASSIGNED,
          value: durationInHours,
          itemStyle: { color: node.activityColor ?? activityColors(node.activityType) },
          isNoEquipment: Number(!node.equipmentName),
        });
      }
    });

    this.setChartXAxisFormatter(this.equipmentChartOption, maxDuration);

    return chartData;
  }

  public prepareTasksChartOption(activityId?: number | number[], equipmentId?: number | number[]): void {
    if (this.showTargets && !this.selectedTaskTargetCategory.length) {
      return;
    }

    if (this.isChartsVertical && !this.isChartDataChanged) {
      this.changeChartsOrientation(true);
    }

    let tasksChartData: IGenericChartData[] = this.prepareTaskChartData(activityId, equipmentId);

    tasksChartData = _.reject(tasksChartData, { activityType: ActivityTypes.RUN_TIME });
    tasksChartData = tasksChartData.filter((taskGroup: IGenericChartData) => taskGroup);
    this.isTaskUsedInActivities = tasksChartData.length !== 0;
    tasksChartData = this.groupExtraChartData(tasksChartData, this.chartConfiguration.tasksChartMaxBarsWithoutOther);
    tasksChartData = this.decideBarLabelPosition(tasksChartData, true);
    const chartNameWithCount: string = this.translate.instant('general.charts.task.title.withCount', {
      count: this.chartConfiguration.tasksChartMaxBarsWithoutOther,
    });
    const chartName: string =
      tasksChartData.length > this.chartConfiguration.tasksChartMaxBarsWithoutOther
        ? chartNameWithCount
        : this.translate.instant('general.charts.task.title.normal');
    this.tasksChartOption.series[0].data = _.clone(tasksChartData);
    this.tasksChartOption.grid = {
      ...this.tasksChartOption.grid,
      top: TasksChartsConstants.taskChartTopPadding,
    };

    this.prepareChartYAxisOptions(this.tasksChartOption, chartName);
    this.prepareChartLayoutOptions(
      this.tasksChartOption,
      chartName,
      this.tooltipTaskTitle,
      this.leftPercentageTasksChart,
      this.chartConfiguration.tasksChartTitleStyle,
    );
    this.toggleTaskChartTargets();
    this.decideTasksBarChartHeight(tasksChartData.length);

    if (this.isChartsVertical && !this.isChartDataChanged) {
      this.changeChartsOrientation(true);
    }
  }

  private prepareTaskChartData(
    activityId: number | number[],
    equipmentId: number | number[],
    activityType?: string | string[],
  ): IGenericChartData[] {
    if (!this.chartData) {
      const { data, maxDuration } = this.prepareTaskChartDataFromRawData(activityId, equipmentId);
      this.setChartXAxisFormatter(this.tasksChartOption, maxDuration);

      return data;
    }

    return this.prepareTaskChartDataFromChartData(activityId, equipmentId);
  }

  private prepareTaskChartDataFromChartData(
    activityId: number | number[],
    equipmentId: number | number[],
  ): IGenericChartData[] {
    let maxDuration: number = 0;
    const chartData: IGenericChartData[] = [];
    const filteredData: IRootCauseAnalysisChartNode[] =
      activityId || equipmentId !== undefined
        ? this.chartData.filter(
            (node: IRootCauseAnalysisChartNode) =>
              (activityId
                ? Array.isArray(activityId)
                  ? activityId.includes(node.activityId)
                  : node.activityId === activityId
                : true) &&
              (equipmentId !== undefined
                ? Array.isArray(equipmentId)
                  ? equipmentId.includes(node.equipmentId)
                  : node.equipmentId === equipmentId
                : true),
          )
        : this.chartData;

    Object.values(_.groupBy(filteredData, 'taskName')).forEach((group: IRootCauseAnalysisChartNode[]) => {
      const node: IRootCauseAnalysisChartNode = _.first(group);
      const duration: number = _.sumBy(group, 'duration');
      const durationInHours: number = transformDurationType(duration, 'seconds', 'hours', this.fourDecimals);
      const targetDuration: string = this.getTotalTarget(group);
      const targetDurationComponents: string[] = group.map((datum: IRootCauseAnalysisChartNode): string => {
        return `(${this.decimalHelper.toFixedValue(datum.targetDuration ?? this.zeroString)} ${
          this.shortHour
        } x ${this.getRepetitionCount(datum)})`;
      });

      if (durationInHours === 0) {
        return;
      }

      maxDuration = Math.max(durationInHours, maxDuration);

      const formattedTask = this.getFormattedTaskName({
        taskId: node.taskId,
        activityType: node.activityType,
        taskName: node.taskName,
        activityColor: node.activityColor,
      });

      chartData.push({
        targetDuration,
        targetDurationFormula: `${targetDurationComponents.join(' + ')} = ${this.decimalHelper.toFixedValue(
          targetDuration ?? this.zeroString,
        )} ${this.shortHour}`,
        originalName: formattedTask.taskName,
        taskId: node.taskId,
        name: _.truncate(formattedTask.taskName, {
          length: this.chartConfiguration.yAxisStyle.labelMaxLength,
        }),
        activityType: node.activityType,
        isMissingData: formattedTask.isMissingData,
        value: durationInHours,
        itemStyle: {
          color: formattedTask.activityColor ?? activityColors(node.activityType),
        },
      });
    });

    this.setChartXAxisFormatter(this.tasksChartOption, maxDuration);

    return chartData;
  }

  private calculateAndSetChartXAxisMaximumValue(): void {
    if (!this.showTargets) {
      this.tasksChartOption.xAxis = { ...this.tasksChartOption.xAxis, max: null };

      return;
    }

    const { data } = this.tasksChartOption.series?.[0];
    const maxDuration: number = _.maxBy(data, (datum: IGenericChartData) => Number(datum.value))?.value;
    const maxTargetDuration: string =
      _.maxBy(data, (datum: IGenericChartData) => Number(datum.targetDuration ?? this.zeroString))?.targetDuration ??
      this.zeroString;

    if (this.decimalHelper.isGreaterThan(String(maxDuration), maxTargetDuration)) {
      this.tasksChartOption.xAxis = { ...this.tasksChartOption.xAxis, max: null };

      return;
    }

    const xAxisMaxValueOffset: string = this.decimalHelper.multiply(maxTargetDuration, '0.05');
    this.tasksChartOption.xAxis = {
      ...this.tasksChartOption.xAxis,
      max: this.decimalHelper.add(maxTargetDuration, xAxisMaxValueOffset),
    };
  }

  private setChartXAxisFormatter(chartOption: echarts.EChartsOption, maxDuration: number): void {
    const oneDecimalThreshold: number = 3.5;
    const twoDecimalsThreshold: number = 0.35;
    const threeDecimalsThreshold: number = 0.035;
    const fourDecimalsThreshold: number = 0.0035;
    const fiveDecimalsThreshold: number = 0.00035;
    let decimalDigit: number = 0;

    if (twoDecimalsThreshold < maxDuration && maxDuration < oneDecimalThreshold) {
      decimalDigit = 1;
    } else if (threeDecimalsThreshold < maxDuration && maxDuration <= twoDecimalsThreshold) {
      decimalDigit = 2;
    } else if (fourDecimalsThreshold < maxDuration && maxDuration <= threeDecimalsThreshold) {
      decimalDigit = 3;
    } else if (fiveDecimalsThreshold < maxDuration && maxDuration <= fourDecimalsThreshold) {
      decimalDigit = 4;
    } else if (maxDuration <= fiveDecimalsThreshold) {
      decimalDigit = 5;
    }

    Object.assign(chartOption.xAxis, {
      axisLabel: {
        ..._.get(chartOption.xAxis, 'axisLabel'),
        formatter: (value) => {
          return this.decimalHelper.toFixedValue(value, decimalDigit);
        },
      },
    });
  }

  private getFilteredTaskGroup(
    activityId: number | number[],
    equipmentId: number | number[],
    taskGroup: IActivityLog[],
  ): IActivityLog[] {
    return activityId || equipmentId !== undefined
      ? taskGroup.filter(
          (logData: IActivityLog) =>
            (activityId
              ? Array.isArray(activityId)
                ? activityId.includes(logData.activityId)
                : logData.activityId === activityId
              : true) &&
            (equipmentId !== undefined
              ? Array.isArray(equipmentId)
                ? equipmentId.includes(logData.equipmentId)
                : logData.equipmentId === equipmentId
              : true),
        )
      : taskGroup;
  }

  private groupExtraChartData(chartData: IGenericChartData[], barsWithoutOther: number): IGenericChartData[] {
    let modifiedChartData: IGenericChartData[] = _.orderBy(_.clone(chartData), 'value', 'desc');
    let otherData: IGenericChartData;

    if (modifiedChartData.length > barsWithoutOther) {
      otherData = {
        originalName: this.othersLabel,
        isOthers: true,
        name: EBarChartGroupedDataLabel.OTHERS,
        value: 0,
        itemStyle: {
          color:
            (_.first(this.activityTypeTexts) === ActivityTypes.DOWN_TIME_PLANNED
              ? this.customColors?.activity.plannedDownTime.card
              : null) ?? activityColors(_.first(this.activityTypeTexts)),
        },
      };
      let totalOtherDuration: number = 0;
      const activityIds: number[] = [];
      const equipmentIds: number[] = [];

      modifiedChartData.slice(barsWithoutOther, modifiedChartData.length).forEach((data: IGenericChartData) => {
        totalOtherDuration += Number(data.value);

        activityIds.push(data.activityId);
        equipmentIds.push(data.equipmentId);
      });

      otherData.value = totalOtherDuration;
      otherData.activityIds = _.clone(activityIds);
      otherData.equipmentIds = _.clone(equipmentIds);
      modifiedChartData.length = barsWithoutOther;
    }

    modifiedChartData = _.orderBy(modifiedChartData, ['isNoEquipment', 'value'], ['desc', 'asc']);

    return otherData ? [otherData].concat(modifiedChartData) : modifiedChartData;
  }

  private decideTasksBarChartHeight(nextDataLength: number): void {
    if (!this.chartConfiguration.shouldResizeTasksChart) {
      return;
    }

    const gapBetweenBars: number = 70 - nextDataLength;
    const chartContainerHeightOffset: number = 150;

    if (this.chartConfiguration.taskChartBarWidth) {
      this.tasksChartRef?.resize({
        width: 'auto',
        height: this.isChartsVertical
          ? 400
          : nextDataLength * (this.chartConfiguration.taskChartBarWidth + gapBetweenBars) + chartContainerHeightOffset,
        animation: this.tasksChartRef.getOption()?.stateAnimation,
      });
    }
  }

  private getTotalDuration(logGroup: IActivityLog[], includeRunTime: boolean = false): number | undefined {
    if (!logGroup) {
      return undefined;
    }

    let totalDuration: number = 0;

    logGroup.forEach((logData: IActivityLog) => {
      if (logData.activityTypeText !== ActivityTypes.RUN_TIME || includeRunTime) {
        totalDuration += Number(logData.duration);
      }
    });

    return totalDuration;
  }

  private decideBarLabelPosition(chartData: IGenericChartData[], isTaskChart: boolean = false): IGenericChartData[] {
    const maxDurationRecord: IGenericChartData | undefined = _.maxBy(chartData, 'value');
    const maxDuration: number = maxDurationRecord !== undefined ? Number(maxDurationRecord.value) : 0;
    const oneSecondInHours: number = 0.0003;

    if (this.showTargets && isTaskChart) {
      return chartData.map((data: IGenericChartData) => {
        return {
          ...data,
          label: {
            ...data.label,
            position: 'insideRight',
            offset: [0, -30],
            color: this.barLabelColor,
          },
        };
      });
    }

    return chartData.map((data: IGenericChartData) => {
      const dataValue: number = Number(data.value);

      return {
        ...data,
        label: {
          ...data.label,
          position:
            (dataValue / maxDuration <= this.chartConfiguration.barLabelPositionThreshold ||
              maxDuration < oneSecondInHours) &&
            dataValue !== maxDuration
              ? 'right'
              : 'insideRight',
          offset: [0, 0],
          color: undefined,
        },
      };
    });
  }

  public static getNumberOfDecimals(value: number): number {
    const threeDecimalsThreshold: number = 0.1;
    const fourDecimalsThreshold: number = 0.01;
    const fiveDecimalsThreshold: number = 0.001;
    let numberOfDecimals: number = 2;

    if (Math.abs(value) < threeDecimalsThreshold) {
      numberOfDecimals = 3;
    }

    if (Math.abs(value) < fourDecimalsThreshold) {
      numberOfDecimals = 4;
    }

    if (Math.abs(value) < fiveDecimalsThreshold) {
      numberOfDecimals = 5;
    }

    return numberOfDecimals;
  }

  private resetEquipmentChartHighlight(): void {
    _.get(this.equipmentChartOption, this.seriesFirstElementData, []).forEach((data: IGenericChartData) => {
      data.itemStyle = { color: data.itemStyle.color };
    });
  }

  private setChartLoadingState(isLoading: boolean): void {
    if (isLoading) {
      const params: IChartLoader = { type: 'default', options: { text: '' } };

      if (!this.activitiesChartRef?.isDisposed()) {
        this.activitiesChartRef?.showLoading(params.type, params.options);
      }

      if (!this.equipmentChartRef?.isDisposed()) {
        this.equipmentChartRef?.showLoading(params.type, params.options);
      }

      if (!this.tasksChartRef?.isDisposed()) {
        this.tasksChartRef?.showLoading(params.type, params.options);
      }

      return;
    }

    if (!this.activitiesChartRef?.isDisposed()) {
      this.activitiesChartRef?.hideLoading();
    }

    if (!this.equipmentChartRef?.isDisposed()) {
      this.equipmentChartRef?.hideLoading();
    }

    if (!this.tasksChartRef?.isDisposed()) {
      this.tasksChartRef?.hideLoading();
    }
  }

  private mergeChartConfigurations(): void {
    this.chartConfiguration = {
      ...this.defaultChartConfiguration,
      ...this.chartConfiguration,
    };

    this.chartConfiguration.highlightStyle = {
      ...this.defaultChartConfiguration.highlightStyle,
      ...this.chartConfiguration.highlightStyle,
    };

    this.chartConfiguration.xAxisStyle = {
      ...this.defaultChartConfiguration.xAxisStyle,
      ...this.chartConfiguration.xAxisStyle,
    };

    this.chartConfiguration.yAxisStyle = {
      ...this.defaultChartConfiguration.yAxisStyle,
      ...this.chartConfiguration.yAxisStyle,
    };
  }

  private checkForEquipmentAndTaskUsage(): void {
    if (this.chartData) {
      this.isTaskUsedInActivities = this.chartData.some((node: IRootCauseAnalysisChartNode) => node.taskId);
      this.isEquipmentUsedInTasks = this.chartData.some((node: IRootCauseAnalysisChartNode) => node.equipmentId);
      this.isNoEquipmentAssignedExists = this.chartData.some((node: IRootCauseAnalysisChartNode) => !node.equipmentId);
      this.noTaskUsedEvent.emit(!this.isTaskUsedInActivities);
      return;
    }

    this.isTaskUsedInActivities = this.rawData.some((logData: IActivityLog) => logData.taskId);
    this.isEquipmentUsedInTasks = this.rawData.some((logData: IActivityLog) => logData.equipmentId);
    this.isNoEquipmentAssignedExists = this.rawData.some((logData: IActivityLog) => !logData.equipmentId);
  }

  private setChartLayout(maxLabelLength?: number): void {
    this.leftPercentageActivitiesChart = this.chartConfiguration.leftPercentageActivitiesChart;
    this.leftPercentageEquipmentChart = this.chartConfiguration.leftPercentageEquipmentChart;
    this.leftPercentageTasksChart = this.chartConfiguration.leftPercentageTasksChart;

    if (!this.activitiesChartOption || !this.equipmentChartOption || !this.tasksChartOption) {
      return;
    }

    const labelLength: number = maxLabelLength ?? this.chartConfiguration.yAxisStyle.labelMaxLength;

    this.activitiesChartOption.grid = {
      ...this.activitiesChartOption.grid,
      containLabel: this.isChartsVertical,
      left: this.chartConfiguration.leftPercentageActivitiesChart,
    };
    this.equipmentChartOption.grid = {
      ...this.activitiesChartOption.grid,
      containLabel: this.isChartsVertical,
      left: this.chartConfiguration.leftPercentageEquipmentChart,
    };
    this.tasksChartOption.grid = {
      ...this.activitiesChartOption.grid,
      top: _.get(this.tasksChartOption, 'grid.top'),
      containLabel: this.isChartsVertical,
      left: this.chartConfiguration.leftPercentageTasksChart,
    };

    this.changeBarChartYAxisLabelLength(this.activitiesChartRef, this.activitiesChartOption, labelLength);
    this.changeBarChartYAxisLabelLength(this.tasksChartRef, this.tasksChartOption, labelLength);
    this.changeBarChartYAxisLabelLength(this.equipmentChartRef, this.equipmentChartOption, labelLength);
  }

  private changeBarChartYAxisLabelLength(
    barChartRef: echarts.ECharts,
    barChartOption: echarts.EChartsOption,
    labelLength: number,
  ): void {
    if (!barChartRef) {
      return;
    }

    barChartOption.series[0].data = barChartOption.series[0]?.data?.map((chartData: IGenericChartData) => {
      let name: string = _.truncate(chartData.originalName, { length: labelLength });

      if (chartData.name === EBarChartGroupedDataLabel.NO_EQUIPMENT_ASSIGNED) {
        name = EBarChartGroupedDataLabel.NO_EQUIPMENT_ASSIGNED;
      }

      if (chartData.name === EBarChartGroupedDataLabel.OTHERS) {
        name = EBarChartGroupedDataLabel.OTHERS;
      }

      return {
        ...chartData,
        name,
      };
    });

    Object.assign(barChartOption.yAxis, {
      data: barChartOption.series[0].data.map((chartData: IGenericChartData) => chartData.name),
    });

    if (!barChartRef.isDisposed()) {
      barChartRef.setOption(barChartOption);
    }
  }

  private prepareChartYAxisOptions(chartOption: echarts.EChartsOption, chartName: string): void {
    chartOption.yAxis = {
      type: 'category',
      name: this.chartConfiguration.yAxisStyle.showName ? chartName : undefined,
      nameLocation: this.chartConfiguration.yAxisStyle.nameLocation,
      nameTextStyle: {
        fontSize: this.chartConfiguration.yAxisStyle.nameFontSize,
        padding: this.chartConfiguration.yAxisStyle.namePadding,
      },
      data: _.get(chartOption, this.seriesFirstElementData, []).map((logData: IGenericChartData) => logData.name),
      axisLabel: {
        formatter: (value: string) => {
          return value === EBarChartGroupedDataLabel.NO_EQUIPMENT_ASSIGNED
            ? `{noEquipment|${this.noEquipmentAssignedLabel}*}`
            : value === EBarChartGroupedDataLabel.OTHERS
            ? `{others|${this.othersLabel}*}`
            : value;
        },
        rich: {
          noEquipment: {
            fontWeight: 'bolder',
          },
          others: {
            fontWeight: 'bolder',
          },
        },
        show: this.chartConfiguration.yAxisStyle.showLabels,
      },
    };
  }

  private prepareChartLayoutOptions(
    chartOption: echarts.EChartsOption,
    chartName: string,
    chartTypeTranslation: string,
    chartLeftPercentage: string,
    chartTitleStyle: IBarChartTitleStyle,
  ): void {
    Object.assign(chartOption, {
      tooltip: {
        confine: true,
        formatter: (params: IChartEventParams) => {
          const typeInformation: string | undefined = params.data.isOthers
            ? undefined
            : `<b>${chartTypeTranslation}:</b> ${params.data.originalName}</br>`;

          return `${typeInformation ?? ''}<b>${this.tooltipDurationTitle}:</b> ${this.decimalHelper.toFixedValue(
            params.value.toString(),
          )} ${this.shortHour}`;
        },
      },
      grid: {
        containLabel: this.isChartsVertical,
        top: _.get(chartOption, 'grid.top'),
        bottom: TasksChartsConstants.topChartsBottomPadding,
        left: chartLeftPercentage,
        right: this.chartConfiguration.rightPercentage,
      },
      title: chartTitleStyle ? { ...chartTitleStyle, text: chartName } : null,
    });
  }

  private prepareActivitiesChartDataFromRawData(): IPreparedData {
    let maxDuration: number = 0;

    const data: IGenericChartData[] = Object.values(this.activityGroups).reduce(
      (result: IGenericChartData[], activityGroup: IActivityLog[]) => {
        const durationInHours: number = transformDurationType(
          this.getTotalDuration(activityGroup, true),
          'seconds',
          'hours',
          this.fourDecimals,
        );
        const firstActivity: IActivityLog = _.first(activityGroup);

        if (!firstActivity || durationInHours === 0) {
          return result;
        }

        maxDuration = Math.max(durationInHours, maxDuration);

        result.push({
          activityId: firstActivity.activityId,
          originalName: firstActivity.activityName,
          activityType: firstActivity.activityTypeText,
          name: _.truncate(firstActivity.activityName, {
            length: this.chartConfiguration.yAxisStyle.labelMaxLength,
          }),
          value: durationInHours,
          itemStyle: { color: firstActivity.activityColor ?? activityColors(firstActivity.activityTypeText) },
        });

        return result;
      },
      [],
    );

    return { data, maxDuration };
  }

  private prepareEquipmentChartDataFromRawData(activityId?: number | number[]): IPreparedData {
    const data: IGenericChartData[] = [];
    let maxDuration: number = 0;

    Object.values(this.equipmentGroups).forEach((equipmentGroup: IActivityLog[]) => {
      const filteredEquipmentGroup: IActivityLog[] = activityId
        ? equipmentGroup.filter((logData: IActivityLog) =>
            Array.isArray(activityId) ? activityId.includes(logData.activityId) : logData.activityId === activityId,
          )
        : equipmentGroup;

      const firstEquipment: IActivityLog = _.first(filteredEquipmentGroup);
      const durationInHours: number = transformDurationType(
        this.getTotalDuration(filteredEquipmentGroup),
        'seconds',
        'hours',
        this.fourDecimals,
      );

      if (durationInHours === 0 || !firstEquipment) {
        return;
      }

      maxDuration = Math.max(durationInHours, maxDuration);
      const downTimePlannedActivityColor: string =
        _.find(filteredEquipmentGroup, { activityTypeText: 'downTimePlanned' })?.activityColor ??
        activityColors('downTimePlanned');

      data.push({
        activityId: firstEquipment.activityId,
        equipmentId: firstEquipment.equipmentId,
        originalName:
          firstEquipment.equipmentName ??
          `${this.noEquipmentAssignedLabel}${this.chartConfiguration.noEquipmentAssignedLabelHasStar ? '*' : ''}`,
        name: firstEquipment.equipmentName
          ? _.truncate(firstEquipment.equipmentName, { length: this.chartConfiguration.yAxisStyle.labelMaxLength })
          : EBarChartGroupedDataLabel.NO_EQUIPMENT_ASSIGNED,
        value: durationInHours,
        itemStyle: { color: downTimePlannedActivityColor },
      });
    });

    return { data, maxDuration };
  }

  private prepareTaskChartDataFromRawData(
    activityId: number | number[],
    equipmentId: number | number[],
  ): IPreparedData {
    const data: IGenericChartData[] = [];
    let maxDuration: number = 0;

    Object.values(this.taskGroups).forEach((taskGroup: IActivityLog[]) => {
      const filteredTaskGroup: IActivityLog[] = this.getFilteredTaskGroup(activityId, equipmentId, taskGroup);

      if (filteredTaskGroup.length < 1) {
        return;
      }

      const durationInHours: number = transformDurationType(
        this.getTotalDuration(filteredTaskGroup),
        'seconds',
        'hours',
        this.fourDecimals,
      );
      const maxDurationLogData: IActivityLog = filteredTaskGroup.reduce(
        (prevLogData: IActivityLog, currentLogData: IActivityLog) =>
          prevLogData.duration > currentLogData.duration ? prevLogData : currentLogData,
      );

      if (durationInHours === 0) {
        return;
      }

      maxDuration = Math.max(durationInHours, maxDuration);

      const formattedTask = this.getFormattedTaskName({
        taskId: maxDurationLogData.taskId,
        activityType: maxDurationLogData.activityTypeText,
        taskName: maxDurationLogData.taskName,
        activityColor: maxDurationLogData.activityColor,
      });

      data.push({
        activityName: maxDurationLogData.activityName,
        equipmentName: maxDurationLogData.equipmentName,
        originalName: formattedTask.taskName,
        taskId: maxDurationLogData.taskId,
        activityType: maxDurationLogData.activityTypeText,
        isMissingData: formattedTask.isMissingData,
        name: _.truncate(formattedTask.taskName, {
          length: this.chartConfiguration.yAxisStyle.labelMaxLength,
        }),
        value: durationInHours,
        itemStyle: {
          color: formattedTask.activityColor,
        },
      });
    });

    return { data, maxDuration };
  }

  public renderEquipmentChart(chartRef?: echarts.ECharts): void {
    if (chartRef) {
      this.equipmentChartRef = chartRef;
    }

    if (!this.equipmentChartRef || chartRef) {
      return;
    }

    this.equipmentChartRef.setOption(this.equipmentChartOption, true);
  }

  public toggleNoEquipment(): void {
    this.unselectNoEquipmentAssignment();
    this.prepareEquipmentChartOption(this.selectedActivityId);
    this.prepareTasksChartOption(
      this.selectedActivityId,
      this.selectedEquipmentId === null ? undefined : this.selectedEquipmentId,
    );
    this.toggleHighlightAfterRender();
    this.renderEquipmentChart();
    this.setEquipmentChartFrameScroll();

    if (this.isChartsVertical) {
      this.chartOptionsMap.forEach((option: string) => {
        if (option === 'tasksChartOption') {
          return;
        }
        this[option].xAxis.data.reverse();
      });
    }
  }

  private setEquipmentChartFrameScroll(toTop: boolean = false): void {
    if (!this.equipmentChartFrame?.nativeElement) {
      return;
    }

    this.equipmentChartFrame.nativeElement.scrollTop =
      this.showNoEquipment && !toTop ? this.equipmentChartFrame.nativeElement.scrollHeight : 0;
  }

  private scrollActivityChartToTop(): void {
    if (!this.activityChartFrame?.nativeElement) {
      return;
    }

    this.activityChartFrame.nativeElement.scrollTop = 0;
  }

  private setTopChartGridTop(top: number, enableAnimation: boolean): void {
    this.activitiesChartOption = {
      ...this.activitiesChartOption,
      grid: {
        ...this.activitiesChartOption.grid,
        top,
      },
      animation: false,
    };
    this.equipmentChartOption = {
      ...this.equipmentChartOption,
      grid: {
        ...this.equipmentChartOption.grid,
        top,
      },
      animation: false,
    };

    if (enableAnimation) {
      setTimeout(() => {
        this.activitiesChartOption.animation = enableAnimation;
        this.equipmentChartOption.animation = enableAnimation;
      });
    }
  }

  private getRepetitionCount(datum: IRootCauseAnalysisChartNode): number {
    switch (this.selectedTaskTargetCategory[0].id as TargetCategory) {
      case TargetCategory.none:
        return datum.taskRepetitionCount;
      case TargetCategory.workOrder:
        return datum.distinctWorkOrderCount;
      case TargetCategory.dayAndShift:
        return datum.distinctDayAndShiftCount;
      case TargetCategory.businessDay:
        return datum.distinctBusinessDayCount;
    }
  }

  private getTotalTarget(group: IRootCauseAnalysisChartNode[]): string {
    return group.reduce(
      (totalTarget: string, datum: IRootCauseAnalysisChartNode) =>
        this.decimalHelper.add(
          totalTarget,
          this.decimalHelper.multiply(datum.targetDuration ?? this.zeroString, String(this.getRepetitionCount(datum))),
        ),
      '0',
    );
  }

  public setRootCauseAnalysisSelectedChartData(displayToastMessage: boolean = true): void {
    if (displayToastMessage) {
      this.toastHelperService.showToastMessage(
        true,
        undefined,
        this.translate.instant('rootCauseAnalysis.charts.selectChartDataInformationMessage'),
      );
    }

    this.store.dispatch(
      new RootCauseAnalysisActions.RootCauseAnalysisSetSelectedChartData(
        this.selectedActivityName,
        this.selectedEquipmentName,
        this.selectedTaskNames,
      ),
    );
  }

  public toggleHighlightAfterRender(): void {
    if (!_.isNil(this.selectedEquipmentBarId)) {
      this.selectedEquipmentBarId += this.showNoEquipment ? 1 : -1;

      _.get(this.equipmentChartOption, this.seriesFirstElementData, []).forEach(
        (data: IGenericChartData, index: number) => {
          if (
            index !== this.selectedEquipmentBarId ||
            data.itemStyle.borderColor ||
            (this.showNoEquipment && data.isNoEquipment)
          ) {
            data.itemStyle = { color: data.itemStyle.color };

            return;
          }

          data.itemStyle = { ...data.itemStyle, ...this.chartConfiguration.highlightStyle };
        },
      );
    }
  }

  public getTaskNamesExcludingOthers(): string[] {
    const taskChartData: IGenericChartData[] = _.cloneDeep(_.first(this.tasksChartOption.series).data);

    return taskChartData
      .filter((chartData: IGenericChartData) => !chartData.isOthers)
      .map((chartData: IGenericChartData) => chartData.originalName);
  }

  public clearSelectedChartData(): void {
    this.selectedActivityId = null;
    this.selectedEquipmentId = null;
    this.selectedEquipmentBarId = null;
    this.selectedActivityName = undefined;
    this.selectedEquipmentName = undefined;
    this.selectedTaskNames = [];
  }

  public unselectNoEquipmentAssignment() {
    if (!this.showNoEquipment && this.selectedEquipmentName === null) {
      this.selectedEquipmentName = undefined;
      this.selectedTaskNames = [];
      this.setRootCauseAnalysisSelectedChartData();
    }
  }

  private getFormattedTaskName(data: {
    taskId: number;
    activityType: string;
    taskName: string;
    activityColor: string;
  }) {
    const isMissingData = !Boolean(data.taskId || data.activityType === ActivityTypes.RUN_TIME);

    if (data.activityType === ActivityTypes.RUN_TIME) {
      return {
        taskName: 'N/A',
        activityColor: data.activityColor,
        isMissingData,
      };
    }

    return {
      taskName: isMissingData ? this.missingDataTranslation : data.taskName,
      activityColor: activityColors(data.activityType, { downTimePlanned: data.activityColor }, isMissingData),
      isMissingData,
    };
  }

  public reverseSeries(series: echarts.BarSeriesOption): echarts.BarSeriesOption {
    return {
      ...series,
      data: series.data?.reverse(),
      label: {
        show: false,
      },
      barWidth: null,
    };
  }

  public reverseAndAdjustAxes(chartOption: any): echarts.EChartsOption {
    return {
      ...chartOption,
      xAxis: {
        ...(chartOption.yAxis as echarts.XAXisComponentOption),
        nameTextStyle: { fontSize: 12, padding: [75, 0, 0, 0] },
        axisLabel: {
          formatter: (value: any) => {
            return value === EBarChartGroupedDataLabel.NO_EQUIPMENT_ASSIGNED
              ? `{noEquipment|${this.noEquipmentAssignedLabel}*}`
              : value === EBarChartGroupedDataLabel.OTHERS
              ? `{others|${this.othersLabel}*}`
              : value;
          },
          width: 100,
          overflow: 'truncate',
          rich: {
            noEquipment: {
              fontWeight: 'bolder',
            },
            others: {
              fontWeight: 'bolder',
            },
          },
          rotate: 45,
          interval: 0,
        },
        nameLocation: 'middle',
      },
      yAxis: {
        ...(chartOption.xAxis as echarts.YAXisComponentOption),
        nameLocation: 'end',
        axisLabel: { show: true },
      },
    };
  }

  private setChartsSizeOnVertical(): void {
    this.tasksChartRef?.resize({
      width: 'auto',
      height: 400,
      animation: this.tasksChartRef.getOption()?.stateAnimation,
    });
  }

  private changeChartsOrientation(isFromTask: boolean = false): void {
    if (this.isChartsVertical) {
      this.chartOptionsMap.forEach((option: string) => {
        if (option !== 'tasksChartOption' && isFromTask) {
          return;
        }

        this[option] = {
          ...this.reverseAndAdjustAxes(this[option]),
          series: [this.reverseSeries(this[option].series[0])],
          grid: {
            ...this[option].grid,
            containLabel: true,
          },
        };
        // @ts-ignore
        this[option].xAxis?.data?.reverse();
      });
      this.setChartsSizeOnVertical();

      if (this.showTargets) {
        this.toggleTaskChartTargets();
      }

      return;
    }
    this.prepareChartOptions();

    if (this.selectedActivityId) {
      this.prepareEquipmentChartOption(this.selectedActivityId);
      this.prepareTasksChartOption(this.selectedActivityId, this.selectedEquipmentId ?? undefined);

      this.activitiesChartOption.series[0].data.forEach((item: IGenericChartData) => {
        if (item.activityId === this.selectedActivityId) {
          item.itemStyle = { ...item.itemStyle, ...this.selectedActivityItemStyle };
        }
      });

      this.setBarColorsToActivityTypeColor(this.selectedActivityItemStyle.color, this.equipmentChartOption);
      this.setTaskBarColorsToActivityTypeColor(this.selectedActivityItemStyle.color, this.tasksChartOption);
    }

    if (this.selectedEquipmentId) {
      this.prepareTasksChartOption(this.selectedActivityId ?? undefined, this.selectedEquipmentId);

      this.equipmentChartOption.series[0].data.forEach((item: IGenericChartData) => {
        if (item.equipmentId === this.selectedEquipmentId) {
          item.itemStyle = { ...item.itemStyle, ...this.selectedEquipmentItemStyle };
        }
      });
    }

    if (this.selectedTaskId) {
      this.tasksChartOption.series[0].data.forEach((item: IGenericChartData) => {
        if (item.taskId === this.selectedTaskId) {
          item.itemStyle = { ...item.itemStyle, ...this.selectedTaskItemStyle };
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
    this.subscriptions?.forEach((subscription: Subscription) => subscription?.unsubscribe());
  }
}
