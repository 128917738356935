import { defaultEnvironmentConfigs } from './environment.defaults';

export const environment = {
  ...defaultEnvironmentConfigs,
  ssoEnv: 'TEST',
  ssoUrl: 'https://ds-sso.scm3d.com',
  appInsights: {
    instrumentationKey: 'b333e547-acc9-4ca1-ad70-610a395e32eb',
    cloudRole: 'df-angular-test',
  },
  fileUploadUrl: 'https://test-file-manager.scm3d.com/',
};
